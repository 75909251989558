package dev.moetz.chatoverlay.model.twitch

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Cheermote(
    val prefix: String,
    val tiers: List<Tier>? = null,
    val type: String? = null,
    val order: Int? = null
) {

    val regex: Regex
        get() = "${this.prefix}([0-9]*)".toRegex()

    @Serializable
    data class Tier(
        @SerialName("min_bits")
        val minBits: Int,
        val id: String,
        val color: String,
        val images: Images,
    ) {
        @Serializable
        data class Images(
            val dark: ThemedImages,
            val light: ThemedImages,
        )

        @Serializable
        data class ThemedImages(
            val animated: ImagesList,
            val static: ImagesList,
        ) {
            fun getImageUrl(): String? {
                val animated = animated.four ?: animated.three ?: animated.two ?: animated.onepointfive ?: animated.one
                val static = static.four ?: static.three ?: static.two ?: static.onepointfive ?: static.one
                return animated ?: static
            }
        }

        @Serializable
        data class ImagesList(
            @SerialName("1")
            val one: String? = null,
            @SerialName("2")
            val two: String? = null,
            @SerialName("3")
            val three: String? = null,
            @SerialName("4")
            val four: String? = null,
            @SerialName("1.5")
            val onepointfive: String? = null,
        )

    }

    fun getTierForBits(bits: Int): Tier? {
        return tiers
            ?.sortedByDescending { it.minBits }
            ?.firstOrNull { it.minBits >= bits }
    }

}
