package dev.moetz.chatoverlay.page

import dev.moetz.chatoverlay.design.applyFooter
import dev.moetz.chatoverlay.design.applyHeader
import dev.moetz.chatoverlay.design.container
import dev.moetz.chatoverlay.design.row
import dev.moetz.werbinich.localization.Localization
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import web.cssom.ClassName

external interface NotFoundPageServiceProps : Props {
}

val NotFoundPageService = FC<NotFoundPageServiceProps> { props ->
    applyHeader()

    container {
        row {
            div {
                css(ClassName("col s12")) {

                }
                p {
                    +Localization["page_not_found"]
                }
            }
        }
    }

    applyFooter()
}