package dev.moetz.chatoverlay.page

import csstype.PropertiesBuilder
import dev.moetz.chatoverlay.chatclient.ChatClient
import dev.moetz.chatoverlay.chatclient.PreviewChatClient
import dev.moetz.chatoverlay.chatclient.TwitchChatClient
import dev.moetz.chatoverlay.manager.EmoteOrText
import dev.moetz.chatoverlay.manager.TextToThirdPartyEmoteManager
import dev.moetz.chatoverlay.model.BroadcasterId
import dev.moetz.chatoverlay.model.IncomingIRC
import dev.moetz.chatoverlay.model.thirdpartyemote.EmoteProviderReloadEvent
import dev.moetz.chatoverlay.model.thirdpartyemote.ThirdPartyEmote
import dev.moetz.chatoverlay.model.twitch.BadgeSet
import dev.moetz.chatoverlay.model.twitch.Cheermote
import dev.moetz.chatoverlay.util.asState
import dev.moetz.reconnectingwebsocket.ReconnectingWebSocketClient
import dev.moetz.werbinich.localization.Localization
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.serialization.json.Json
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import web.cssom.*
import web.dom.document
import web.url.URLSearchParams
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

external interface ChatOverlayServiceProps : Props {
    var manager: ChatOverlayServiceManager
}


@OptIn(ExperimentalStdlibApi::class)
class ChatOverlayServiceManager(
    scope: CoroutineScope = GlobalScope,
    private val textToThirdPartyEmoteManager: TextToThirdPartyEmoteManager = TextToThirdPartyEmoteManager(),
) {

    fun getBadgeUrl(
        broadcasterId: BroadcasterId?,
        badgeSetNameWithNumber: IncomingIRC.BadgeSetNameWithNumber
    ): String? {
        return if (badgeSetNameWithNumber.set == "subscriber") {
            mutableChannelBadgeSetStateFlow.value[broadcasterId]
                ?.firstOrNull { it.setId == "subscriber" }
                ?.versions
                ?.firstOrNull { it.id == badgeSetNameWithNumber.value }
                ?.imageUrl4x
        } else if (badgeSetNameWithNumber.set == "bits") {
            mutableChannelBadgeSetStateFlow.value[broadcasterId]
                ?.firstOrNull { it.setId == "bits" }
                ?.versions
                ?.firstOrNull { it.id == badgeSetNameWithNumber.value }
                ?.imageUrl4x
        } else {
            globalBadgeSetsStateFlow
                .value
                .firstOrNull { it.setId == badgeSetNameWithNumber.set }
                ?.versions
                ?.firstOrNull { it.id == badgeSetNameWithNumber.value }
                ?.imageUrl4x
        }
    }

    private val json = Json

    private val mutableGlobalBadgeSetsStateFlow: MutableStateFlow<List<BadgeSet>> = MutableStateFlow(emptyList())
    val globalBadgeSetsStateFlow: StateFlow<List<BadgeSet>> = mutableGlobalBadgeSetsStateFlow.asStateFlow()

    private val mutableChannelBadgeSetStateFlow: MutableStateFlow<Map<BroadcasterId, List<BadgeSet>>> =
        MutableStateFlow(emptyMap())
    val channelBadgeSetStateFlow: StateFlow<Map<BroadcasterId, List<BadgeSet>>> =
        mutableChannelBadgeSetStateFlow.asStateFlow()
    private val channelBadgeSetMutex = Mutex()

    private val mutableGlobalThirdPartyEmotesStateFlow: MutableStateFlow<List<ThirdPartyEmote>> =
        MutableStateFlow(emptyList())
    val globalThirdPartyEmotesStateFlow: StateFlow<List<ThirdPartyEmote>> =
        mutableGlobalThirdPartyEmotesStateFlow.asStateFlow()

    private val mutableChannelThirdPartyEmotesStateFlow: MutableStateFlow<Map<BroadcasterId, List<ThirdPartyEmote>>> =
        MutableStateFlow(emptyMap())
    val channelThirdPartyEmotesStateFlow: StateFlow<Map<BroadcasterId, List<ThirdPartyEmote>>> =
        mutableChannelThirdPartyEmotesStateFlow.asStateFlow()
    private val channelThirdPartyEmotesMutex = Mutex()

    private val mutableCheermotesStateFlow: MutableStateFlow<Map<BroadcasterId, List<Cheermote>>> =
        MutableStateFlow(emptyMap())
    val cheermotesStateFlow: StateFlow<Map<BroadcasterId, List<Cheermote>>> = mutableCheermotesStateFlow.asStateFlow()
    private val cheermotesMutex = Mutex()

    val fontColor: String
    val fontSize: Int
    val shadowColor: String?
    val shadowBlurRadius: Double
    val backgroundColor: Triple<Int, Int, Int>?
    val backgroundAlpha: Double
    val showChannelProfileImage: Boolean
    private val hiddenUsernames: List<String>
    private val hideCommands: Boolean
    private val loadRecentMessages: Boolean
    val disableSharedChatMessages: Boolean
    val showPredictionBadges: Boolean
    val showSubscriberBadges: Boolean
    val showModerationBadges: Boolean
    val messageDelay: Duration?

    private val isPreview: Boolean
    private val loggingEnabled: Boolean

    val twitchChatClient: ChatClient

    private val mutableMessagesWithEmotesStateFlow: MutableStateFlow<List<Pair<IncomingIRC.Message, List<EmoteOrText>>>> =
        MutableStateFlow(emptyList())
    val messagesWithEmotesStateFlow = mutableMessagesWithEmotesStateFlow.asStateFlow()

    private val mutableIsSharedCharActiveStateFlow: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val isSharedCharActiveStateFlow = mutableIsSharedCharActiveStateFlow.asStateFlow()

    private val combinedEmoteProviderUpdateEventsWebSocket: ReconnectingWebSocketClient

    init {
        val urlSearchParams = URLSearchParams(window.location.search)
        isPreview = urlSearchParams["preview"]?.toBoolean() ?: false
        loggingEnabled = urlSearchParams["loggingEnabled"]?.toBoolean() ?: false

        val channels = urlSearchParams["channels"]?.split(",").orEmpty().map { it.lowercase() }
        fontColor = urlSearchParams["fontColor"]?.filter { it.isLetterOrDigit() || it == '#' } ?: "black"
        fontSize = urlSearchParams["fontSize"]?.toIntOrNull() ?: 24
        shadowColor = urlSearchParams["shadowColor"]?.filter { it.isLetterOrDigit() || it == '#' }
        shadowBlurRadius = urlSearchParams["shadowBlurRadius"]?.toDoubleOrNull() ?: 5.0
        backgroundColor =
            urlSearchParams["backgroundColor"]?.filter { it.isLetterOrDigit() || it == '#' }?.let { hexString ->
                try {
                    val rHex = "${hexString.getOrNull(1)}${hexString.getOrNull(2)}".hexToInt()
                    val gHex = "${hexString.getOrNull(3)}${hexString.getOrNull(4)}".hexToInt()
                    val bHex = "${hexString.getOrNull(5)}${hexString.getOrNull(6)}".hexToInt()
                    Triple(rHex, gHex, bHex)
                } catch (throwable: Throwable) {
                    null
                }
            }
        backgroundAlpha = urlSearchParams["backgroundAlpha"]?.toDoubleOrNull() ?: 1.0
        showChannelProfileImage = urlSearchParams["showChannelProfileImage"]?.toBoolean() ?: true
        hiddenUsernames = urlSearchParams["hiddenUsernames"]?.split(",")?.map { it.trim().lowercase() }.orEmpty()
        hideCommands = urlSearchParams["hideCommands"]?.toBoolean() ?: false
        loadRecentMessages = urlSearchParams["loadRecentMessages"]?.toBoolean() ?: true
        disableSharedChatMessages = urlSearchParams["disableSharedChatMessages"]?.toBoolean() ?: false
        showPredictionBadges = urlSearchParams["showPredictionBadges"]?.toBoolean() ?: true
        showSubscriberBadges = urlSearchParams["showSubscriberBadges"]?.toBoolean() ?: true
        showModerationBadges = urlSearchParams["showModerationBadges"]?.toBoolean() ?: true
        messageDelay = urlSearchParams["messageDelay"]?.toIntOrNull()?.seconds

        val messageAllowedPredicate: (IncomingIRC.Message) -> Boolean = { message ->
            val disallowedDueToWrongRoom = disableSharedChatMessages && message.isFromDifferentRoom
            val allowedAccordingToCommand = hideCommands.not() || message.message.startsWith("!").not()
            val allowedAccordingToDisplayName =
                (message.displayName.orEmpty().lowercase() in hiddenUsernames).not()

            allowedAccordingToCommand && allowedAccordingToDisplayName && disallowedDueToWrongRoom.not()
        }

        twitchChatClient = if (isPreview) {
            PreviewChatClient(
                apiBaseUrl = window.location.href,
                loggingEnabled = loggingEnabled,
                messageAllowedPredicate = messageAllowedPredicate
            )
        } else {
            TwitchChatClient(
                apiBaseUrl = window.location.href,
                messageShowCount = 50,
                messageAllowedPredicate = messageAllowedPredicate,
                loggingEnabled = loggingEnabled,
                messageDelay = messageDelay,
            )
        }

        combinedEmoteProviderUpdateEventsWebSocket = ReconnectingWebSocketClient(
            url = getFullWebsocketUrlForPath("api/combined/updates?channels=${channels.joinToString(separator = ",")}"),
            startToRetryInstantly = false,
            debugOutput = loggingEnabled,
        )

        twitchChatClient.seenRoomIdsFlow
            .distinctUntilChanged()
            .onEach { broadcasterIds ->
                broadcasterIds.forEach { broadcasterId ->
                    twitchChatClient.getChannelEmotesByBroadcasterId(broadcasterId)?.also {
                        channelBadgeSetMutex.withLock {
                            mutableChannelBadgeSetStateFlow.value = mutableChannelBadgeSetStateFlow.value
                                .toMutableMap()
                                .apply {
                                    this[broadcasterId] = it
                                }

                        }
                    }
                }

                broadcasterIds.forEach { broadcasterId ->
                    loadThirdPartyEmotesForChannel(broadcasterId = broadcasterId)
                }
                broadcasterIds.forEach { broadcasterId ->
                    twitchChatClient.getCheermotes(broadcasterId)?.also {
                        cheermotesMutex.withLock {
                            mutableCheermotesStateFlow.value =
                                mutableCheermotesStateFlow.value
                                    .toMutableMap()
                                    .apply {
                                        this[broadcasterId] = it
                                    }
                        }
                    }
                }
            }
            .launchIn(scope)

        scope.launch {
            twitchChatClient.connectAndJoinChannels(channels, loadRecentMessages)

            this.launch {
                twitchChatClient.getGlobalBadges()?.also {
                    mutableGlobalBadgeSetsStateFlow.emit(it)
                }
            }

            this.launch {
                twitchChatClient.getCombinedGlobalEmotes()?.also {
                    mutableGlobalThirdPartyEmotesStateFlow.emit(it)
                }
            }

            combinedEmoteProviderUpdateEventsWebSocket.received
                .mapNotNull { serialized ->
                    try {
                        json.decodeFromString(EmoteProviderReloadEvent.serializer(), serialized)
                    } catch (throwable: Throwable) {
                        throwable.printStackTrace()
                        null
                    }
                }
                .onEach { event ->
                    loadThirdPartyEmotesForChannel(
                        broadcasterId = event.broadcasterId
                    )
                }
                .flowOn(Dispatchers.Default)
                .launchIn(this)

            combinedEmoteProviderUpdateEventsWebSocket.connect()

        }

        scope.launch {
            combine(
                twitchChatClient.messagesStateFlow,
                globalThirdPartyEmotesStateFlow,
                channelThirdPartyEmotesStateFlow,
                cheermotesStateFlow,
            ) { messages, globalThirdPartyEmotes, channelThirdPartyEmotes, cheermotes ->
                Triple(messages, globalThirdPartyEmotes, Pair(channelThirdPartyEmotes, cheermotes))
            }
                .map { (messages, globalThirdPartyEmotes, channelThirdPartyEmotesAndCheermotes) ->
                    val (channelThirdPartyEmotes, cheermotes) = channelThirdPartyEmotesAndCheermotes
                    messages.map { message ->
                        val channelEmotes =
                            channelThirdPartyEmotes[message.actualRoomId?.let { BroadcasterId(it) }].orEmpty()
                        val emotes = globalThirdPartyEmotes + channelEmotes

                        val emotesCleanedUpWithZeroWidth = textToThirdPartyEmoteManager.checkZeroWidthEmotes(
                            textToThirdPartyEmoteManager.parseThirdPartyEmotes(
                                message,
                                emotes = emotes,
                                cheermotes = cheermotes[message.actualRoomId?.let { BroadcasterId(it) }].orEmpty(),
                            )
                        )

                        Pair(message, emotesCleanedUpWithZeroWidth)
                    }
                }
                .distinctUntilChanged()
                .onEach {
                    mutableMessagesWithEmotesStateFlow.value = it
                }
                .launchIn(this)
        }

        twitchChatClient.messagesStateFlow
            .mapNotNull { messages -> messages.lastOrNull() }
            .map { message -> message.sourceRoomId != null }
            .distinctUntilChanged()
            .onEach { isInSharedRoom ->
                mutableIsSharedCharActiveStateFlow.value = isInSharedRoom
            }
            .launchIn(scope)
    }

    private suspend fun loadThirdPartyEmotesForChannel(broadcasterId: BroadcasterId) {
        twitchChatClient.getCombinedChannelEmotes(broadcasterId)?.also {
            channelThirdPartyEmotesMutex.withLock {
                mutableChannelThirdPartyEmotesStateFlow.value =
                    mutableChannelThirdPartyEmotesStateFlow.value
                        .toMutableMap()
                        .apply {
                            this[broadcasterId] = it
                        }
            }
        }
    }


    private fun getFullWebsocketUrlForPath(path: String): String {
        return buildString {
            val hostname = window.location.hostname
            val port = window.location.port
            val isSecure = window.location.protocol == "https:"
            if (isSecure) {
                append("wss://")
            } else {
                append("ws://")
            }
            append(hostname)
            if (port.isBlank().not()) {
                append(':')
                append(port)
            }
            if (path.startsWith('/').not()) {
                append('/')
            }
            append(path)
        }
    }

}


val ChatOverlayService = FC<ChatOverlayServiceProps> { props ->
    val connected = props.manager.twitchChatClient.connectedFlow.asState()
    val messagesWithEmotes = props.manager.messagesWithEmotesStateFlow.asState()
    val isSharedChatActive = props.manager.isSharedCharActiveStateFlow.asState()
    val fontColor = props.manager.fontColor
    val fontSize = props.manager.fontSize
    val shadowColor = props.manager.shadowColor
    val shadowBlurRadius = props.manager.shadowBlurRadius
    val backgroundColor = props.manager.backgroundColor
    val backgroundAlpha = props.manager.backgroundAlpha
    val showChannelProfileImage =
        props.manager.showChannelProfileImage || (props.manager.disableSharedChatMessages.not() && isSharedChatActive)

    // flow subscribed here as its value is used in the getBadgeUrl method, and we want updates
    val globalBadgeSetsStateFlow = props.manager.globalBadgeSetsStateFlow.asState()
    val channelBadgeSetStateFlow = props.manager.channelBadgeSetStateFlow.asState()

    div {
        css {
            if (backgroundColor != null) {
                this.backgroundColor = rgb(
                    backgroundColor.first,
                    backgroundColor.second,
                    backgroundColor.third,
                    backgroundAlpha
                )
            }
        }

        if (connected.not()) {
            span {
                css {
                    color = Color("red")
                }
                +Localization["overlay_loading"]
            }
        }

        div {
            css {
                this.fontSize = fontSize.px
                padding = 6.px
                fontFamily = string("Roboto")
                fontWeight = integer(500)
                display = Display.flex
                flexDirection = FlexDirection.columnReverse
            }
            messagesWithEmotes.reversed().forEach { (message, emotesCleanedUpWithZeroWidth) ->
                div {
                    css {
                        color = Color(fontColor)
                        if (shadowColor != null) {
                            textShadow = TextShadow(0.px, 0.px, shadowBlurRadius.px, Color(shadowColor))
                        }
                    }

                    // channel image
                    if (showChannelProfileImage) {
                        image(
                            url = "/twitch/${message.actualRoomId}/profileImage.png",
                            key = "${message.id}_${message.actualRoomId}_profileImage",
                            height = 0.75.em,
                            marginRight = 6.px,
                            borderRadius = 50.pct,
                        )
                    }

                    // badges
                    message.actualBadges
                        .asSequence()
                        .filter {
                            props.manager.showPredictionBadges || it.set.equals("predictions", ignoreCase = true).not()
                        }
                        .filter {
                            props.manager.showModerationBadges || it.set.equals("moderator", ignoreCase = true).not()
                        }
                        .filter {
                            props.manager.showSubscriberBadges || it.set.equals("subscriber", ignoreCase = true).not()
                        }
                        .forEach { badgeWithNumber ->
                            val url = props.manager.getBadgeUrl(
                                message.actualRoomId?.let { BroadcasterId(it) },
                                badgeWithNumber
                            )
                            if (url != null) {
                                image(
                                    url = url,
                                    key = "${message.id}_${message.actualRoomId}_badge_${badgeWithNumber.value}_${badgeWithNumber.set}",
                                    height = 0.75.em,
                                    marginRight = 3.px,
                                    marginLeft = 3.px,
                                )
                            }
                        }

                    // username
                    span {
                        css {
                            message.color?.also {
                                color = Color(it)
                            }
                            marginLeft = 3.px
                            fontWeight = integer(800)
                        }
                        +"${message.displayName}: "
                    }

                    if (message.isSlashMe) {
                        i {
                            showMessageWithEmotes(message, emotesCleanedUpWithZeroWidth)
                        }
                    } else {
                        showMessageWithEmotes(message, emotesCleanedUpWithZeroWidth)
                    }


                    onLoad = {
                        window.scrollTo(0.0, document.body.scrollHeight.toDouble())
                    }
                }
            }
        }
    }
}

fun ignoreCaseOpt(ignoreCase: Boolean) =
    if (ignoreCase) setOf(RegexOption.IGNORE_CASE) else emptySet()

fun String.indexesOf(pat: String, ignoreCase: Boolean = true): List<Int> {
    return try {
        pat.toRegex(ignoreCaseOpt(ignoreCase))
            .findAll(this)
            .map { it.range.first }
            .toList()
    } catch (throwable: Throwable) {
        println("Error regexing '$pat'")
        throwable.printStackTrace()
        emptyList()
    }
}


private fun ChildrenBuilder.showMessageWithEmotes(
    message: IncomingIRC.Message,
    emotesWithCheer: List<EmoteOrText>
) {
    emotesWithCheer.forEachIndexed { index, emoteOrText ->
        when (emoteOrText) {
            is EmoteOrText.TwitchEmote, is EmoteOrText.ParsedEmote -> {
                val (url, label) = when (emoteOrText) {
                    is EmoteOrText.TwitchEmote -> {
                        Pair(
                            "https://static-cdn.jtvnw.net/emoticons/v2/${emoteOrText.emoteWithLocation.emoteId}/default/light/3.0",
                            emoteOrText.emoteWithLocation.name,
                        )
                    }

                    is EmoteOrText.ParsedEmote -> {
                        Pair(
                            emoteOrText.emote.url,
                            emoteOrText.emote.name,
                        )
                    }

                    else -> {
                        Pair(null, null)
                    }
                }

                if (emoteOrText.zeroWidthEmoteOverlays.isNotEmpty()) {
                    span {
                        css {
                            position = Position.relative
                            display = Display.inlineFlex
                            justifyContent = JustifyContent.center
                            flexDirection = FlexDirection.row
                            verticalAlign = VerticalAlign.textTop
                        }

                        val labelWithZeroWidths = (listOf(label) + emoteOrText.zeroWidthEmoteOverlays.map { it.name }).joinToString(separator = ", ")

                        span {
                            image(
                                url = url,
                                key = "${message.id}_${index}",
                                height = 1.em,
                                marginLeft = 0.px,
                                marginRight = 0.px,
                                title = labelWithZeroWidths,
                            )
                        }
                        emoteOrText.zeroWidthEmoteOverlays.forEachIndexed { zeroWidthIndex, zeroWidthEmote ->
                            span {
                                css {
                                    position = Position.absolute
                                }
                                image(
                                    url = zeroWidthEmote.url,
                                    key = "${message.id}_${index}_${zeroWidthIndex}",
                                    height = 1.em,
                                    marginLeft = 0.px,
                                    marginRight = 0.px,
                                    title = labelWithZeroWidths,
                                )
                            }
                        }
                    }
                } else {
                    image(
                        url = url,
                        key = "${message.id}_${index}",
                        height = 1.em,
                        marginLeft = 0.px,
                        marginRight = 0.px,
                        title = label,
                        additionalCss = {
                            verticalAlign = VerticalAlign.textTop
                        }
                    )
                }
            }

            is EmoteOrText.Cheer -> {
                +"${emoteOrText.amount} "

                val url = emoteOrText.emote.getTierForBits(emoteOrText.amount)?.images?.light?.getImageUrl()
                image(
                    url = url,
                    key = "${message.id}_${index}",
                    title = "${emoteOrText.emote.prefix} (${emoteOrText.amount} bits)",
                    height = 0.85.em,
                    marginLeft = 0.px,
                    marginRight = 0.px,
                )
            }

            is EmoteOrText.Text -> {
                span {
                    +emoteOrText.text
                }
            }
        }
    }
}

fun ChildrenBuilder.image(
    url: String?,
    key: String,
    title: String? = null,
    height: Length = 0.85.em,
    marginLeft: Length = 2.px,
    marginRight: Length = 2.px,
    borderRadius: Length? = null,
    additionalCss: (PropertiesBuilder.() -> Unit)? = null,
) {
    img {
        css {
            this.height = height
            this.marginLeft = marginLeft
            this.marginRight = marginRight

            // Wanted to use minWidth here to _reserve_ the space when the emote is still loading, however this made
            // issues with smaller emotes (e.g. FeelsBirthdayMan)
//            this.minWidth = 0.8.em
            if (borderRadius != null) {
                this.borderRadius = borderRadius
            }
            additionalCss?.invoke(this)
        }
        this.title = title
        src = url
        this.key = key
    }
}
