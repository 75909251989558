package dev.moetz.chatoverlay

import dev.moetz.chatoverlay.model.IncomingIRC
import io.ktor.client.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

class RecentMessagesLoader(
    private val apiBaseUrl: String = "https://recent-messages-provider.joey.moetz.dev/",
    private val loggingEnabled: Boolean,
    private val json: Json = Json,
) {
    @Serializable
    private data class RecentMessagesResponse(
        val messages: List<String>,
        val error: String?,
        val error_code: String?
    )

    protected val httpClient: HttpClient by lazy {
        HttpClient() {
            if (loggingEnabled) {
                install(Logging)
            }
        }
    }

    suspend fun getRecentMessages(channelName: String, limit: Int): List<IncomingIRC.Message>? {
        val url = buildString {
            if (apiBaseUrl.endsWith("/")) {
                append(apiBaseUrl.substringBeforeLast("/"))
            } else {
                append(apiBaseUrl)
            }
            append("/api/v2/recent-messages/$channelName?limit=$limit")
        }
        val response = httpClient.get(urlString = url)
        return if (response.status.isSuccess()) {
            val text = response.bodyAsText()
            val messages = json.decodeFromString(RecentMessagesResponse.serializer(), text).messages
            messages
                .mapNotNull { IncomingIRC.parse(rawMessage = it) }
                .let { rawList ->
                    mutableListOf<IncomingIRC.Message>().also { messageList ->
                        rawList.forEach { ircMessage ->
                            when (ircMessage) {
                                is IncomingIRC.Message -> messageList.add(ircMessage)

                                is IncomingIRC.Usernotice -> {
                                    val message = ircMessage.toMessage()
                                    if (message != null) {
                                        messageList.add(message)
                                    }
                                }

                                is IncomingIRC.ClearMessage -> {
                                    messageList.removeAll { it.id == ircMessage.targetMessageId }
                                }

                                is IncomingIRC.ClearChat -> {
                                    messageList.removeAll { it.userId == ircMessage.targetUserId }
                                }
                            }
                        }
                    }
                }
        } else {
            println("Api error: $response")
            null
        }
    }

}