package dev.moetz.chatoverlay.manager

import dev.moetz.chatoverlay.model.IncomingIRC
import dev.moetz.chatoverlay.model.thirdpartyemote.ThirdPartyEmote
import dev.moetz.chatoverlay.model.twitch.Cheermote
import dev.moetz.chatoverlay.page.indexesOf

class TextToThirdPartyEmoteManager {

    /**
     * Merges emotes that are proceeded by zero-width emotes in one emote with the zero width emote(s) appended to them.
     */
    fun checkZeroWidthEmotes(unfilteredElements: List<EmoteOrText>): List<EmoteOrText> {
        val filteredElements = unfilteredElements.filterNot { it is EmoteOrText.Text && it.text.isEmpty() }

        var output = zeroWidthInternal(filteredElements)
        do {
            val input = output
            output = zeroWidthInternal(input)
        } while (output.size != input.size)

        return output
    }

    private fun EmoteOrText.isAZeroWidthEmote(): Boolean {
        return if (this is EmoteOrText.ParsedEmote) {
            this.emote.isZeroWidth
        } else {
            false
        }
    }

    private fun zeroWidthInternal(input: List<EmoteOrText>): List<EmoteOrText> {
        if (input.none { it is EmoteOrText.ParsedEmote && it.emote.isZeroWidth == true }) {
            return input
        }
        val result = mutableListOf<EmoteOrText>()
        var index = 0
        while (index < input.size) {
            val element = input[index]
            if (element is EmoteOrText.ParsedEmote || element is EmoteOrText.TwitchEmote) {
                val hasSpace = input
                    .getOrNull(index + 1)
                    ?.let { it is EmoteOrText.Text && it.text.isBlank() } == true

                val nextElement = input.getOrNull(index + 2)

                if (hasSpace && nextElement != null && nextElement is EmoteOrText.ParsedEmote && element.isAZeroWidthEmote().not() && nextElement.emote.isZeroWidth) {
                    val newElement = when (element) {
                        is EmoteOrText.ParsedEmote -> {
                            element.copy(
                                zeroWidthEmoteOverlays = element.zeroWidthEmoteOverlays + EmoteOrText.ZeroWidthEmoteOverlay(
                                    name = nextElement.emote.name,
                                    url = nextElement.emote.url
                                )
                            )
                        }

                        is EmoteOrText.TwitchEmote -> {
                            element.copy(
                                zeroWidthEmoteOverlays = element.zeroWidthEmoteOverlays + EmoteOrText.ZeroWidthEmoteOverlay(
                                    name = nextElement.emote.name,
                                    url = nextElement.emote.url
                                )
                            )
                        }

                        else -> throw NoWhenBranchMatchedException("$element is invalid here")
                    }
                    result.add(newElement)
                    index += 2
                } else {
                    result.add(input[index])
                }
            } else {
                result.add(input[index])
            }
            index++
        }
        return result
    }

    fun parseThirdPartyEmotes(
        message: IncomingIRC.Message,
        emotes: List<ThirdPartyEmote>,
        cheermotes: List<Cheermote>,
    ): List<EmoteOrText> {
        val emotePartsAndTextParts = mutableListOf<EmoteOrText>()
        var currentIndex = 0
        message.emotes.sortedBy { it.startIndex }
            .forEach { emoteWithLocation ->
                if (emoteWithLocation.startIndex != currentIndex) {
                    emotePartsAndTextParts.addAll(
                        getTextAndOrSevenTvEmotesAndOrCheerEmotes(
                            text = message.message.substring(currentIndex, emoteWithLocation.startIndex),
                            emotes = emotes,
                        )
                    )
                }
                emotePartsAndTextParts.add(
                    EmoteOrText.TwitchEmote(emoteWithLocation, emptyList())
                )
                currentIndex = emoteWithLocation.endIndex + 1
            }
        if (currentIndex != message.message.lastIndex || message.emotes.isEmpty()) {
            emotePartsAndTextParts.addAll(
                getTextAndOrSevenTvEmotesAndOrCheerEmotes(
                    text = message.message.substring(currentIndex),
                    emotes = emotes,
                )
            )
        }

        return if (message.bits != null && message.bits > 0) {
            emotePartsAndTextParts
                .flatMap { emoteOrText ->
                    if (emoteOrText is EmoteOrText.Text) {
                        val matchedCheermotes = cheermotes.filter { cheermote ->
                            cheermote.regex.containsMatchIn(emoteOrText.text)
                        }

                        matchedCheermotes
                            .flatMap { cheermote ->
                                cheermote.regex.findAll(emoteOrText.text)
                                    .map { matchResult ->
                                        val range = matchResult.range
                                        val amount = matchResult.value
                                            .substring(cheermote.prefix.length)
                                            .toIntOrNull()

                                        EmoteOrText.Cheer(
                                            emote = cheermote,
                                            start = range.first,
                                            end = range.last,
                                            amount = amount ?: 0,
                                        )
                                    }
                                    .filter { it.amount > 0 }
                            }
                            .takeIf { it.isNotEmpty() }
                            ?: listOf(emoteOrText)
                    } else {
                        listOf(emoteOrText)
                    }
                }
            // no amount verification yet
//            .takeIf { list ->
//                val bitSumInCheerEmotes = list.sumOf {
//                    if (it is EmoteOrText.Cheermote) {
//                        it.amount
//                    } else {
//                        0
//                    }
//                }
//                bitSumInCheerEmotes <= message.bits
//            }
        } else {
            emotePartsAndTextParts
        }
    }

    private fun getTextAndOrSevenTvEmotesAndOrCheerEmotes(
        text: String,
        emotes: List<ThirdPartyEmote>,
    ): List<EmoteOrText> {
        val matchingEmotes = text
            .split(" ")
            .asSequence()
            .map { it.trim() }
            .filter { word ->
                emotes.any { it.name.equals(word, ignoreCase = true) }
            }
            .toList()

        val emotesAndTheirOccurrences = matchingEmotes.distinct()
            .flatMap { word ->
                val emote = emotes.firstOrNull { it.name == word }
                val occurrences = text.indexesOf(word, ignoreCase = true)

                if (emote != null && occurrences.isNotEmpty()) {
                    occurrences
                        .map { it to it + emote.name.length }
                        .filter { (startIndex, endIndex) ->
                            val startChecksOut = startIndex == 0 || text[startIndex - 1].isWhitespaceSpecial()
                            val endChecksOut = endIndex == text.lastIndex || text[endIndex].isWhitespaceSpecial()
                            startChecksOut && endChecksOut
                        }
                        .map { emote to it }
                } else {
                    emptyList()
                }
            }

        val emotePartsAndTextParts = mutableListOf<EmoteOrText>()
        var currentIndex = 0
        emotesAndTheirOccurrences
            .sortedBy { it.second.first }
            .forEach { pairOfPair ->
                val (emote, pair) = pairOfPair
                val (from, to) = pair

                if (from != currentIndex) {
                    emotePartsAndTextParts.add(
                        EmoteOrText.Text(text.substring(currentIndex, from))
                    )
                }
                emotePartsAndTextParts.add(
                    EmoteOrText.ParsedEmote(emote, from, to, emptyList())
                )
                currentIndex = to
            }

        if (currentIndex != text.lastIndex || emotesAndTheirOccurrences.isEmpty()) {
            emotePartsAndTextParts.add(
                EmoteOrText.Text(text.substring(currentIndex))
            )
        }

        return emotePartsAndTextParts
    }


    private fun Char.isWhitespaceSpecial(): Boolean {
        return this.isWhitespace() || (this.isLetterOrDigit() || this.code in 33..127).not()
    }

}