package dev.moetz.chatoverlay.manager

import dev.moetz.chatoverlay.model.IncomingIRC
import dev.moetz.chatoverlay.model.thirdpartyemote.ThirdPartyEmote
import dev.moetz.chatoverlay.model.twitch.Cheermote

sealed class EmoteOrText {

    data class ZeroWidthEmoteOverlay(
        val name: String,
        val url: String,
    )

    abstract val zeroWidthEmoteOverlays: List<ZeroWidthEmoteOverlay>

    data class TwitchEmote(
        val emoteWithLocation: IncomingIRC.EmoteWithLocation,
        override val zeroWidthEmoteOverlays: List<ZeroWidthEmoteOverlay>,
    ) : EmoteOrText()

    data class ParsedEmote(
        val emote: ThirdPartyEmote,
        val start: Int,
        val end: Int,
        override val zeroWidthEmoteOverlays: List<ZeroWidthEmoteOverlay>,
    ) : EmoteOrText()

    data class Cheer(
        val emote: Cheermote,
        val start: Int,
        val end: Int,
        val amount: Int,
    ) : EmoteOrText() {
        override val zeroWidthEmoteOverlays: List<ZeroWidthEmoteOverlay> get() = emptyList()
    }

    data class Text(
        val text: String,
    ) : EmoteOrText() {
        override val zeroWidthEmoteOverlays: List<ZeroWidthEmoteOverlay> get() = emptyList()
    }
}
