package dev.moetz.chatoverlay.model.thirdpartyemote

import kotlinx.serialization.Serializable

@Serializable
data class ThirdPartyEmote(
    val name: String,
    val url: String,
    val isZeroWidth: Boolean,
)
