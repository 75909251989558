package dev.moetz.werbinich.localization

import kotlinx.serialization.json.Json
import web.navigator.navigator

external val require: dynamic

object Localization {

    enum class Language(val identifier: String) {
        EN("en"),
        DE("de"),
    }

    private val String.asLanguage: Language?
        get() {
            return if (this.contains("-")) {
                Language.entries.firstOrNull {
                    it.identifier.equals(
                        this.substringBefore("-").trim(),
                        ignoreCase = true
                    )
                }
            } else {
                Language.entries.firstOrNull { it.identifier.equals(this.trim(), ignoreCase = true) }
            }
        }

    private val currentLanguage: Language by lazy {
        val language = if (navigator.languages != undefined) {
            navigator.languages.toList().asSequence().mapNotNull { it.asLanguage }.firstOrNull()
        } else {
            navigator.language.asLanguage
        }
        language ?: Language.EN
    }

    private val entries: Map<String, String> by lazy {
        val jsonContent = JSON.stringify(require("./locales/${currentLanguage.identifier}/frontend.json"))
        val content = Json.decodeFromString<Map<String, String>>(jsonContent)
        content
    }

    operator fun get(key: String): String {
        return entries[key].orEmpty()
    }

    fun get(key: String, vararg arguments: Any): String {
        var entry = entries[key].orEmpty()
        arguments.forEachIndexed { index, value ->
            val number = index + 1
            entry = when (value) {
                is Int, is Long -> entry.replace("%$number${'$'}d", value.toString())
                is Float, is Double -> entry.replace("%$number${'$'}f", value.toString())
                else -> entry.replace("%$number${'$'}s", value.toString())
            }
        }
        return entry
    }


}