package dev.moetz.chatoverlay.page

import dev.moetz.chatoverlay.chatclient.ChatClient
import dev.moetz.chatoverlay.chatclient.PreviewChatClient
import dev.moetz.chatoverlay.chatclient.TwitchChatClient
import dev.moetz.chatoverlay.model.IncomingIRC
import dev.moetz.chatoverlay.model.thirdpartyemote.EmoteProviderReloadEvent
import dev.moetz.chatoverlay.model.thirdpartyemote.ThirdPartyEmote
import dev.moetz.chatoverlay.model.twitch.BadgeSet
import dev.moetz.chatoverlay.model.twitch.Cheermote
import dev.moetz.chatoverlay.model.twitch.User
import dev.moetz.chatoverlay.util.useStateFlow
import dev.moetz.reconnectingwebsocket.ReconnectingWebSocketClient
import dev.moetz.werbinich.localization.Localization
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.serialization.json.Json
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import web.cssom.*
import web.dom.document
import web.url.URLSearchParams
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

external interface ChatOverlayServiceProps : Props {
    var manager: ChatOverlayServiceManager
}


@OptIn(ExperimentalStdlibApi::class)
class ChatOverlayServiceManager(
    private val scope: CoroutineScope = GlobalScope,
) {

    fun getBadgeUrl(
        channelName: String,
        badgeSetNameWithNumber: IncomingIRC.BadgeSetNameWithNumber
    ): String? {
        return if (badgeSetNameWithNumber.set == "subscriber") {
            mutableChannelBadgeSetStateFlow.value[channelName.toLowerCase()]
                ?.takeIf { it.setId == "subscriber" }
                ?.versions
                ?.firstOrNull { it.id == badgeSetNameWithNumber.value.toString() }
                ?.imageUrl4x
        } else {
            globalBadgeSetsStateFlow
                .value
                .firstOrNull { it.setId == badgeSetNameWithNumber.set }
                ?.versions
                ?.firstOrNull { it.id == badgeSetNameWithNumber.value.toString() }
                ?.imageUrl4x
        }
    }

    fun getChannelImageUrl(channelName: String): String? {
        return users[channelName.toLowerCase()]?.profileImageUrl
    }

    private val json = Json

    private val mutableGlobalBadgeSetsStateFlow: MutableStateFlow<List<BadgeSet>> = MutableStateFlow(emptyList())
    val globalBadgeSetsStateFlow: StateFlow<List<BadgeSet>> = mutableGlobalBadgeSetsStateFlow.asStateFlow()

    private val mutableChannelBadgeSetStateFlow: MutableStateFlow<Map<String, BadgeSet>> = MutableStateFlow(emptyMap())
    val channelBadgeSetStateFlow: StateFlow<Map<String, BadgeSet>> = mutableChannelBadgeSetStateFlow.asStateFlow()
    private val channelBadgeSetMutex = Mutex()

    val users: MutableMap<String, User> = mutableMapOf()

    private val mutableGlobalThirdPartyEmotesStateFlow: MutableStateFlow<List<ThirdPartyEmote>> =
        MutableStateFlow(emptyList())
    val globalThirdPartyEmotesStateFlow: StateFlow<List<ThirdPartyEmote>> =
        mutableGlobalThirdPartyEmotesStateFlow.asStateFlow()

    private val mutableChannelThirdPartyEmotesStateFlow: MutableStateFlow<Map<String, List<ThirdPartyEmote>>> =
        MutableStateFlow(emptyMap())
    val channelThirdPartyEmotesStateFlow: StateFlow<Map<String, List<ThirdPartyEmote>>> =
        mutableChannelThirdPartyEmotesStateFlow.asStateFlow()
    private val channelThirdPartyEmotesMutex = Mutex()

    private val mutableCheermotesStateFlow: MutableStateFlow<Map<String, List<Cheermote>>> =
        MutableStateFlow(emptyMap())
    val cheermotesStateFlow: StateFlow<Map<String, List<Cheermote>>> = mutableCheermotesStateFlow.asStateFlow()
    private val cheermotesMutex = Mutex()

    val fontColor: String
    val fontSize: Int
    val shadowColor: String?
    val shadowBlurRadius: Double
    val backgroundColor: Triple<Int, Int, Int>?
    val backgroundAlpha: Double
    val showChannelProfileImage: Boolean
    private val hiddenUsernames: List<String>
    private val hideCommands: Boolean
    private val loadRecentMessages: Boolean
    val showPredictionBadges: Boolean
    val showSubscriberBadges: Boolean
    val showModerationBadges: Boolean
    val messageDelay: Duration?

    private val isPreview: Boolean
    private val loggingEnabled: Boolean

    val twitchChatClient: ChatClient


    private val combinedEmoteProviderUpdateEventsWebSocket: ReconnectingWebSocketClient

    init {
        val urlSearchParams = URLSearchParams(window.location.search)
        isPreview = urlSearchParams["preview"]?.toBoolean() ?: false
        loggingEnabled = urlSearchParams["loggingEnabled"]?.toBoolean() ?: false

        val channels = urlSearchParams["channels"]?.split(",").orEmpty().map { it.toLowerCase() }
        fontColor = urlSearchParams["fontColor"]?.filter { it.isLetterOrDigit() || it == '#' } ?: "black"
        fontSize = urlSearchParams["fontSize"]?.toIntOrNull() ?: 24
        shadowColor = urlSearchParams["shadowColor"]?.filter { it.isLetterOrDigit() || it == '#' }
        shadowBlurRadius = urlSearchParams["shadowBlurRadius"]?.toDoubleOrNull() ?: 5.0
        backgroundColor =
            urlSearchParams["backgroundColor"]?.filter { it.isLetterOrDigit() || it == '#' }?.let { hexString ->
                try {
                    val rHex = "${hexString.getOrNull(1)}${hexString.getOrNull(2)}".hexToInt()
                    val gHex = "${hexString.getOrNull(3)}${hexString.getOrNull(4)}".hexToInt()
                    val bHex = "${hexString.getOrNull(5)}${hexString.getOrNull(6)}".hexToInt()
                    Triple(rHex, gHex, bHex)
                } catch (throwable: Throwable) {
                    null
                }
            }
        backgroundAlpha = urlSearchParams["backgroundAlpha"]?.toDoubleOrNull() ?: 1.0
        showChannelProfileImage = urlSearchParams["showChannelProfileImage"]?.toBoolean() ?: true
        hiddenUsernames = urlSearchParams["hiddenUsernames"]?.split(",")?.map { it.trim().toLowerCase() }.orEmpty()
        hideCommands = urlSearchParams["hideCommands"]?.toBoolean() ?: false
        loadRecentMessages = urlSearchParams["loadRecentMessages"]?.toBoolean() ?: true
        showPredictionBadges = urlSearchParams["showPredictionBadges"]?.toBoolean() ?: true
        showSubscriberBadges = urlSearchParams["showSubscriberBadges"]?.toBoolean() ?: true
        showModerationBadges = urlSearchParams["showModerationBadges"]?.toBoolean() ?: true
        messageDelay = urlSearchParams["messageDelay"]?.toIntOrNull()?.seconds

        val messageAllowedPredicate: (IncomingIRC.Message) -> Boolean = { message ->
            val allowedAccordingToCommand = hideCommands.not() || message.message.startsWith("!").not()
            val allowedAccordingToDisplayName =
                (message.displayName.orEmpty().toLowerCase() in hiddenUsernames).not()

            allowedAccordingToCommand && allowedAccordingToDisplayName
        }

        twitchChatClient = if (isPreview) {
            PreviewChatClient(
                apiBaseUrl = window.location.href,
                loggingEnabled = loggingEnabled,
                messageAllowedPredicate = messageAllowedPredicate
            )
        } else {
            TwitchChatClient(
                apiBaseUrl = window.location.href,
                messageShowCount = 50,
                messageAllowedPredicate = messageAllowedPredicate,
                loggingEnabled = loggingEnabled,
                messageDelay = messageDelay,
            )
        }

        combinedEmoteProviderUpdateEventsWebSocket = ReconnectingWebSocketClient(
            url = getFullWebsocketUrlForPath("api/combined/updates?channels=${channels.joinToString(separator = ",")}"),
            startToRetryInstantly = false,
            debugOutput = loggingEnabled,
        )

        scope.launch {
            twitchChatClient.connectAndJoinChannels(channels, loadRecentMessages)

            this.launch {
                twitchChatClient.getGlobalBadges()?.also {
                    mutableGlobalBadgeSetsStateFlow.emit(it)
                }
            }

            this.launch {
                twitchChatClient.getCombinedGlobalEmotes()?.also {
                    mutableGlobalThirdPartyEmotesStateFlow.emit(it)
                }
            }

            channels.forEachParallel(scope) { channelName ->
                val deferred1 = async {
                    twitchChatClient.getChannelEmotes(channelName)?.also {
                        channelBadgeSetMutex.withLock {
                            mutableChannelBadgeSetStateFlow.value = mutableChannelBadgeSetStateFlow.value
                                .toMutableMap()
                                .apply {
                                    this[channelName.toLowerCase()] = it
                                }

                        }
                    }
                }

                val deferred2 = async {
                    twitchChatClient.getUser(channelName.toLowerCase())?.also {
                        users[channelName.toLowerCase()] = it
                    }
                }

                val deferred3 = async {
                    loadThirdPartyEmotesForChannel(channelName = channelName)
                }

                val deferred4 = async {
                    twitchChatClient.getCheermotes(channelName.toLowerCase())?.also {
                        cheermotesMutex.withLock {
                            mutableCheermotesStateFlow.value =
                                mutableCheermotesStateFlow.value
                                    .toMutableMap()
                                    .apply {
                                        this[channelName.toLowerCase()] = it
                                    }
                        }
                    }
                }

                listOf(deferred1, deferred2, deferred3, deferred4).awaitAll()
            }

            combinedEmoteProviderUpdateEventsWebSocket.received
                .mapNotNull { serialized ->
                    try {
                        json.decodeFromString(EmoteProviderReloadEvent.serializer(), serialized)
                    } catch (throwable: Throwable) {
                        throwable.printStackTrace()
                        null
                    }
                }
                .onEach { event ->
                    loadThirdPartyEmotesForChannel(channelName = event.channel)
                }
                .flowOn(Dispatchers.Default)
                .launchIn(this)

            combinedEmoteProviderUpdateEventsWebSocket.connect()

        }
    }

    private suspend fun loadThirdPartyEmotesForChannel(channelName: String) {
        twitchChatClient.getCombinedChannelEmotes(channelName.toLowerCase())?.also {
            channelThirdPartyEmotesMutex.withLock {
                mutableChannelThirdPartyEmotesStateFlow.value =
                    mutableChannelThirdPartyEmotesStateFlow.value
                        .toMutableMap()
                        .apply {
                            this[channelName.toLowerCase()] = it
                        }
            }
        }
    }


    private fun getFullWebsocketUrlForPath(path: String): String {
        return buildString {
            val hostname = window.location.hostname
            val port = window.location.port
            val isSecure = window.location.protocol == "https:"
            if (isSecure) {
                append("wss://")
            } else {
                append("ws://")
            }
            append(hostname)
            if (port.isBlank().not()) {
                append(':')
                append(port)
            }
            if (path.startsWith('/').not()) {
                append('/')
            }
            append(path)
        }
    }

}

suspend inline fun <T> List<T>.forEachParallel(scope: CoroutineScope, crossinline block: suspend (T) -> Unit) {
    this
        .map { item ->
            scope.async {
                block.invoke(item)
            }
        }
        .awaitAll()
}


val ChatOverlayService = FC<ChatOverlayServiceProps> { props ->
    val connected = useStateFlow(props.manager.twitchChatClient.connectedFlow)
    val messages = useStateFlow(props.manager.twitchChatClient.messagesStateFlow)
    val fontColor = props.manager.fontColor
    val fontSize = props.manager.fontSize
    val shadowColor = props.manager.shadowColor
    val shadowBlurRadius = props.manager.shadowBlurRadius
    val backgroundColor = props.manager.backgroundColor
    val backgroundAlpha = props.manager.backgroundAlpha
    val showChannelProfileImage = props.manager.showChannelProfileImage

    // flow subscribed here as its value is used in the getBadgeUrl method, and we want updates
    val globalBadgeSetsStateFlow = useStateFlow(props.manager.globalBadgeSetsStateFlow)
    val channelBadgeSetStateFlow = useStateFlow(props.manager.channelBadgeSetStateFlow)
    val globalThirdPartyEmotesStateFlow = useStateFlow(props.manager.globalThirdPartyEmotesStateFlow)
    val channelThirdPartyEmotesStateFlow = useStateFlow(props.manager.channelThirdPartyEmotesStateFlow)
    val cheermotesStateFlow = useStateFlow(props.manager.cheermotesStateFlow)

    div {
        css {
            if (backgroundColor != null) {
                this.backgroundColor = rgb(
                    backgroundColor.first,
                    backgroundColor.second,
                    backgroundColor.third,
                    backgroundAlpha
                )
            }
        }

        if (connected.not()) {
            span {
                css {
                    color = Color("red")
                }
                +Localization["overlay_loading"]
            }
        }

        div {
            css {
                this.fontSize = fontSize.px
                padding = 6.px
                fontFamily = string("Roboto")
                fontWeight = integer(500)
                display = Display.flex
                flexDirection = FlexDirection.columnReverse
            }
            messages.reversed().forEach { message ->
                val emotesCleanedUpWithZeroWidth = checkZeroWidthEmotes(
                    emoteParseStuff(
                        message,
                        emotes = globalThirdPartyEmotesStateFlow + channelThirdPartyEmotesStateFlow[message.channel.orEmpty()].orEmpty(),
                        cheermotes = cheermotesStateFlow[message.channel.orEmpty()].orEmpty(),
                    )
                )

                div {
                    css {
                        color = Color(fontColor)
                        if (shadowColor != null) {
                            textShadow = TextShadow(0.px, 0.px, shadowBlurRadius.px, Color(shadowColor))
                        }
                    }

                    // channel image
                    if (showChannelProfileImage) {
                        val url = props.manager.getChannelImageUrl(message.channel.orEmpty())
                        if (url != null) {
                            img {
                                css {
                                    height = 0.75.em
                                    marginRight = 6.px
                                    borderRadius = 50.pct
                                }
                                src = url
                            }
                        }
                    }


                    // badges
                    message.badges
                        .asSequence()
                        .filter {
                            props.manager.showPredictionBadges || it.set.equals("predictions", ignoreCase = true).not()
                        }
                        .filter {
                            props.manager.showModerationBadges || it.set.equals("moderator", ignoreCase = true).not()
                        }
                        .filter {
                            props.manager.showSubscriberBadges || it.set.equals("subscriber", ignoreCase = true).not()
                        }
                        .forEach {
                            val url = props.manager.getBadgeUrl(message.channel.orEmpty(), it)
                            if (url != null) {
                                img {
                                    css {
                                        height = 0.75.em
                                        marginRight = 3.px
                                        marginLeft = 3.px
                                    }
                                    src = url
                                }
                            }
                        }

                    // username
                    span {
                        css {
                            message.color?.also {
                                color = Color(it)
                            }
                            marginLeft = 3.px
                            fontWeight = integer(800)
                        }
                        +"${message.displayName}: "
                    }

                    showMessageWithEmotes(emotesCleanedUpWithZeroWidth)

                    onLoad = {
                        window.scrollTo(0.0, document.body.scrollHeight.toDouble())
                    }
                }
            }
        }
    }
}

fun ignoreCaseOpt(ignoreCase: Boolean) =
    if (ignoreCase) setOf(RegexOption.IGNORE_CASE) else emptySet()

fun String.indexesOf(pat: String, ignoreCase: Boolean = true): List<Int> {
    return try {
        pat.toRegex(ignoreCaseOpt(ignoreCase))
            .findAll(this)
            .map { it.range.first }
            .toList()
    } catch (throwable: Throwable) {
        println("Error regexing '$pat'")
        throwable.printStackTrace()
        emptyList()
    }
}

private fun getTextAndOrSevenTvEmotesAndOrCheerEmotes(
    text: String,
    emotes: List<ThirdPartyEmote>,
): List<EmoteOrText> {
    val matchingEmotes = text
        .split(" ")
        .asSequence()
        .map { it.trim() }
        .filter { word ->
            emotes.any { it.name.equals(word, ignoreCase = true) }
        }
        .toList()

    val emotesAndTheirOccurrences = matchingEmotes.distinct()
        .flatMap { word ->
            val emote = emotes.firstOrNull { it.name == word }
            val occurrences = text.indexesOf(word, ignoreCase = true)

            if (emote != null && occurrences.isNotEmpty()) {
                occurrences
                    .map { it to it + emote.name.length }
                    .filter { (startIndex, endIndex) ->
                        val startChecksOut = startIndex == 0 || text[startIndex - 1].isWhitespaceSpecial()
                        val endChecksOut = endIndex == text.lastIndex || text[endIndex].isWhitespaceSpecial()
                        startChecksOut && endChecksOut
                    }
                    .map { emote to it }
            } else {
                emptyList()
            }
        }

    val emotePartsAndTextParts = mutableListOf<EmoteOrText>()
    var currentIndex = 0
    emotesAndTheirOccurrences
        .sortedBy { it.second.first }
        .forEach { pairOfPair ->
            val (emote, pair) = pairOfPair
            val (from, to) = pair

            if (from != currentIndex) {
                emotePartsAndTextParts.add(
                    EmoteOrText.Text(text.substring(currentIndex, from))
                )
            }
            emotePartsAndTextParts.add(
                EmoteOrText.ParsedEmote(emote, from, to, null)
            )
            currentIndex = to
        }

    if (currentIndex != text.lastIndex || emotesAndTheirOccurrences.isEmpty()) {
        emotePartsAndTextParts.add(
            EmoteOrText.Text(text.substring(currentIndex))
        )
    }

    return emotePartsAndTextParts
}

private fun Char.isWhitespaceSpecial(): Boolean {
    return this.isWhitespace() || (this.isLetterOrDigit() || this.code in 33..127).not()
}


private fun emoteParseStuff(
    message: IncomingIRC.Message,
    emotes: List<ThirdPartyEmote>,
    cheermotes: List<Cheermote>,
): List<EmoteOrText> {
    val emotePartsAndTextParts = mutableListOf<EmoteOrText>()
    var currentIndex = 0
    message.emotes.sortedBy { it.startIndex }
        .forEach { emoteWithLocation ->
            if (emoteWithLocation.startIndex != currentIndex) {
                emotePartsAndTextParts.addAll(
                    getTextAndOrSevenTvEmotesAndOrCheerEmotes(
                        text = message.message.substring(currentIndex, emoteWithLocation.startIndex),
                        emotes = emotes,
                    )
                )
            }
            emotePartsAndTextParts.add(
                EmoteOrText.TwitchEmote(emoteWithLocation, null)
            )
            currentIndex = emoteWithLocation.endIndex + 1
        }
    if (currentIndex != message.message.lastIndex || message.emotes.isEmpty()) {
        emotePartsAndTextParts.addAll(
            getTextAndOrSevenTvEmotesAndOrCheerEmotes(
                text = message.message.substring(currentIndex),
                emotes = emotes,
            )
        )
    }

    return if (message.bits != null && message.bits > 0) {
        emotePartsAndTextParts
            .flatMap { emoteOrText ->
                if (emoteOrText is EmoteOrText.Text) {
                    val matchedCheermotes = cheermotes.filter { cheermote ->
                        cheermote.regex.containsMatchIn(emoteOrText.text)
                    }

                    matchedCheermotes
                        .flatMap { cheermote ->
                            cheermote.regex.findAll(emoteOrText.text)
                                .map { matchResult ->
                                    val range = matchResult.range
                                    val amount = matchResult.value
                                        .substring(cheermote.prefix.length)
                                        .toIntOrNull()

                                    EmoteOrText.Cheer(
                                        emote = cheermote,
                                        start = range.first,
                                        end = range.last,
                                        amount = amount ?: 0,
                                    )
                                }
                                .filter { it.amount > 0 }
                        }
                        .takeIf { it.isNotEmpty() }
                        ?: listOf(emoteOrText)
                } else {
                    listOf(emoteOrText)
                }
            }
        // no amount verification yet
//            .takeIf { list ->
//                val bitSumInCheerEmotes = list.sumOf {
//                    if (it is EmoteOrText.Cheermote) {
//                        it.amount
//                    } else {
//                        0
//                    }
//                }
//                bitSumInCheerEmotes <= message.bits
//            }
    } else {
        emotePartsAndTextParts
    }
}

private fun checkZeroWidthEmotes(unfilteredElements: List<EmoteOrText>): List<EmoteOrText> {
    val filteredElements = unfilteredElements.filterNot { it is EmoteOrText.Text && it.text.isEmpty() }


    val result = mutableListOf<EmoteOrText>()
    var index = 0
    while (index < filteredElements.size) {
        val element = filteredElements[index]
        if (element is EmoteOrText.ParsedEmote || element is EmoteOrText.TwitchEmote) {
            val hasSpace = filteredElements
                .getOrNull(index + 1)
                ?.let { it is EmoteOrText.Text && it.text.isBlank() } == true

            val nextElement = filteredElements.getOrNull(index + 2)
            if (hasSpace && nextElement != null && nextElement is EmoteOrText.ParsedEmote && nextElement.emote.isZeroWidth) {
                val newElement = when (element) {
                    is EmoteOrText.ParsedEmote -> {
                        element.copy(
                            zeroWidthEmoteOverlay = nextElement.emote.url
                        )
                    }

                    is EmoteOrText.TwitchEmote -> {
                        element.copy(
                            zeroWidthEmoteOverlay = nextElement.emote.url
                        )
                    }

                    else -> throw NoWhenBranchMatchedException("$element is invalid here")
                }
                result.add(newElement)
                index += 2
            } else {
                result.add(filteredElements[index])
            }
        } else {
            result.add(filteredElements[index])
        }
        index++
    }
    return result
}

private fun ChildrenBuilder.showMessageWithEmotes(
    emotesWithCheer: List<EmoteOrText>
) {
    emotesWithCheer.forEach { emoteOrText ->
        when (emoteOrText) {
            is EmoteOrText.TwitchEmote, is EmoteOrText.ParsedEmote -> {
                val (url, label) = when (emoteOrText) {
                    is EmoteOrText.TwitchEmote -> {
                        Pair(
                            "https://static-cdn.jtvnw.net/emoticons/v2/${emoteOrText.emoteWithLocation.emoteId}/default/light/3.0",
                            emoteOrText.emoteWithLocation.name
                        )
                    }

                    is EmoteOrText.ParsedEmote -> {
                        Pair(
                            emoteOrText.emote.url,
                            emoteOrText.emote.name
                        )
                    }

                    else -> {
                        null to null
                    }
                }

                if (emoteOrText.zeroWidthEmoteOverlay != null) {
                    img {
                        css {
                            height = 0.9.em
                            marginLeft = 2.px
                            marginRight = 2.px
                            backgroundImage = web.cssom.url(url.orEmpty())
                            backgroundSize = BackgroundSize.contain
                        }
                        src = emoteOrText.zeroWidthEmoteOverlay
                        title = label
                    }
                } else {
                    img {
                        css {
                            height = 0.9.em
                            marginLeft = 2.px
                            marginRight = 2.px
                        }
                        src = url
                        title = label
                    }
                }

            }

            is EmoteOrText.Cheer -> {
                +"${emoteOrText.amount} "
                img {
                    css {
                        height = 0.85.em
                        marginLeft = 2.px
                        marginRight = 2.px
                    }
                    title = "${emoteOrText.emote.prefix} (${emoteOrText.amount} bits)"
                    src = emoteOrText.emote.getTierForBits(emoteOrText.amount)?.images?.light?.getImageUrl()
                }
            }

            is EmoteOrText.Text -> {
                span {
                    +emoteOrText.text
                }
            }
        }
    }
}

sealed class EmoteOrText {

    abstract val zeroWidthEmoteOverlay: String?

    data class TwitchEmote(
        val emoteWithLocation: IncomingIRC.EmoteWithLocation,
        override val zeroWidthEmoteOverlay: String?,
    ) : EmoteOrText()

    data class ParsedEmote(
        val emote: ThirdPartyEmote,
        val start: Int,
        val end: Int,
        override val zeroWidthEmoteOverlay: String?,
    ) : EmoteOrText()

    data class Cheer(
        val emote: Cheermote,
        val start: Int,
        val end: Int,
        val amount: Int,
    ) : EmoteOrText() {
        override val zeroWidthEmoteOverlay: String? get() = null
    }

    data class Text(
        val text: String,
    ) : EmoteOrText() {
        override val zeroWidthEmoteOverlay: String? get() = null
    }
}