package dev.moetz.chatoverlay.model.twitch

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class BadgeSet(
    @SerialName("set_id")
    val setId: String,
    val versions: List<ChatBadge>
) {
    @Serializable
    data class ChatBadge(
        val id: String,
        @SerialName("image_url_1x")
        val imageUrl1x: String,
        @SerialName("image_url_2x")
        val imageUrl2x: String,
        @SerialName("image_url_4x")
        val imageUrl4x: String,
        val title: String,
        val description: String,
    )
}