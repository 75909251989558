package dev.moetz.chatoverlay.page

import dev.moetz.chatoverlay.design.mFooter
import dev.moetz.materialize.*
import dev.moetz.werbinich.localization.Localization
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.b
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.iframe
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import react.useState
import web.cssom.*
import web.html.InputType
import web.navigator.navigator
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

external interface ConfigPageServiceProps : Props {
    var manager: ConfigPageServiceManager
}

class ConfigPageServiceManager(
    private val scope: CoroutineScope = GlobalScope,
) {

    fun writeClipboard(data: String) {
        scope.launch {
            navigator.clipboard.writeText(data)
        }
    }

}

private fun generateOverlayLink(
    channelNamesInput: String,
    fontColorInput: String,
    fontSizeInput: Int,
    hiddenUsernamesInput: String,
    showChannelProfileImage: Boolean,
    hideCommands: Boolean,
    loadRecentMessages: Boolean,
    disableSharedChatMessages: Boolean,
    showPredictionBadges: Boolean,
    showSubscriberBadges: Boolean,
    showModerationBadges: Boolean,
    messageDelay: Duration?,
    shadowColorInput: String,
    shadowBlurRadiusInput: Double,
    backgroundColorInput: String,
    backgroundAlphaInput: Double,
    isPreview: Boolean = false,
): String {
    val location = window.location.href

    val channels = channelNamesInput
        .split(",")
        .map { it.trim() }
        .filter { rawChannel ->
            rawChannel.isNotBlank() && rawChannel.all { it.isLetterOrDigit() || it == '_' }
        }
        .map { it.lowercase() }

    val hiddenUsernames = hiddenUsernamesInput
        .split(",")
        .map { it.trim() }
        .filter { rawChannel ->
            rawChannel.isNotBlank() && rawChannel.all { it.isLetterOrDigit() || it == '_' }
        }
        .map { it.lowercase() }

    val fontColor = fontColorInput
        .filter { it == '#' || it.isLetterOrDigit() }
        .replace("#", "%23")

    val shadowColor = shadowColorInput
        .filter { it == '#' || it.isLetterOrDigit() }
        .replace("#", "%23")

    val backgroundColor = backgroundColorInput
        .filter { it == '#' || it.isLetterOrDigit() }
        .replace("#", "%23")

    return buildString {
        append(location)
        if (location.endsWith("/").not()) {
            append("/")
        }
        append("overlay")
        append("?channels=${channels.joinToString(separator = ",")}")
        append("&fontColor=$fontColor")
        append("&fontSize=$fontSizeInput")
        append("&hiddenUsernames=${hiddenUsernames.joinToString(separator = ",")}")
        append("&showChannelProfileImage=$showChannelProfileImage")
        append("&hideCommands=${hideCommands}")
        append("&loadRecentMessages=$loadRecentMessages")
        if (disableSharedChatMessages) {
            append("&disableSharedChatMessages=$disableSharedChatMessages")
        }
        append("&showPredictionBadges=$showPredictionBadges")
        append("&showSubscriberBadges=$showSubscriberBadges")
        append("&showModerationBadges=$showModerationBadges")
        append("&messageDelay=${messageDelay?.inWholeSeconds ?: 0}")
        append("&shadowColor=$shadowColor")
        append("&shadowBlurRadius=$shadowBlurRadiusInput")
        append("&backgroundColor=$backgroundColor")
        append("&backgroundAlpha=$backgroundAlphaInput")
        if (isPreview) {
            append("&preview=true")
        }
    }
}

val ConfigPageService = FC<ConfigPageServiceProps> { props ->
    var channelNamesInput by useState("")
    var fontColorInput by useState("")
    var fontSizeInput by useState(24)
    var hiddenUsernamesInput by useState("")
    var showChannelProfileImage by useState(false)
    var hideCommands by useState(false)
    var loadRecentMessages by useState(true)
    var disableSharedChatMessages by useState(false)
    var showPredictionBadges by useState(true)
    var showSubscriberBadges by useState(true)
    var showModerationBadges by useState(true)
    var messageDelay by useState<Duration?>(null)
    var shadowColorInput by useState("")
    var shadowBlurRadiusInput by useState(5.0)
    var backgroundColorInput by useState("")
    var backgroundAlphaInput by useState(0.9)

    var darkPreviewBackground by useState(false)

    mHeader(title = Localization["page_title"])

    mContainer {
        mRow {
            mCol(small = 12) {
                p {
                    +Localization["config_page_explanation"]
                }
            }
        }

        mRow {
            // twitch channels
            mCol(small = 12, medium = 12, large = 12, additionalClass = "input-field") {
                input {
                    className = ClassName("white-text")
                    id = "channelNames"
                    placeholder = Localization["config_page_channelNames_placeholder"]
                    type = InputType.text
                    value = channelNamesInput
                    onChange = {
                        channelNamesInput = it.target.value
                    }
                }
                label {
                    className = ClassName("active")
                    htmlFor = "channelNames"
                    +Localization["config_page_channelNames_label"]
                }
            }

            // font color
            colorChooser(
                htmlId = "fontColor",
                placeholder = Localization["config_page_fontColor_placeholder"],
                label = Localization["config_page_fontColor_label"],
                value = fontColorInput,
                onChange = { fontColorInput = it }
            )

            // hidden usernames
            div {
                className = ClassName("input-field col s12 m6 l6")
                input {
                    className = ClassName("white-text")
                    id = "hiddenUsernames"
                    placeholder = Localization["config_page_hiddenUsernames_placeholder"]
                    type = InputType.text
                    value = hiddenUsernamesInput
                    onChange = {
                        hiddenUsernamesInput = it.target.value
                    }
                }
                label {
                    className = ClassName("active")
                    htmlFor = "hiddenUsernames"
                    +Localization["config_page_hiddenUsernames_label"]
                }
            }

            // show channel profile image
            checkbox(
                falseLabel = Localization["config_page_showChannelProfileImage_false_label"],
                trueLabel = Localization["config_page_showChannelProfileImage_true_label"],
                value = showChannelProfileImage,
                onChange = { showChannelProfileImage = it }
            )

            // font size
            numberInput(
                htmlId = "fontSize",
                label = Localization["config_page_fontSize_label"],
                placeholderText = Localization.get("config_page_fontSize_placeholder", 24),
                default = 24.0,
                min = 4.0,
                max = 128.0,
                step = 1.0,
                value = fontSizeInput.toDouble(),
                onChange = {
                    fontSizeInput = it?.toInt() ?: 24
                }
            )

            // hide commands
            checkbox(
                falseLabel = Localization["config_page_hideCommands_false_label"],
                trueLabel = Localization["config_page_hideCommands_true_label"],
                value = hideCommands.not(),
                onChange = { hideCommands = it.not() }
            )

            // load recent messages
            checkbox(
                falseLabel = Localization["config_page_loadRecentMessages_false_label"],
                trueLabel = Localization["config_page_loadRecentMessages_true_label"],
                value = loadRecentMessages,
                onChange = { loadRecentMessages = it }
            )

            // disable shared chat messages
            checkbox(
                falseLabel = Localization["config_page_disableSharedChatMessages_false_label"],
                trueLabel = Localization["config_page_disableSharedChatMessages_true_label"],
                value = disableSharedChatMessages,
                onChange = { disableSharedChatMessages = it }
            )

            // show prediction badges
            checkbox(
                falseLabel = Localization["config_page_showPredictionBadges_false_label"],
                trueLabel = Localization["config_page_showPredictionBadges_true_label"],
                value = showPredictionBadges,
                onChange = { showPredictionBadges = it }
            )

            // show subscriber badges
            checkbox(
                falseLabel = "Do not show subscriber badges",
                trueLabel = "Show subscriber badges",
                value = showSubscriberBadges,
                onChange = { showSubscriberBadges = it }
            )

            // show subscriber badges
            checkbox(
                falseLabel = Localization["config_page_showSubscriberBadges_false_label"],
                trueLabel = Localization["config_page_showSubscriberBadges_true_label"],
                value = showModerationBadges,
                onChange = { showModerationBadges = it }
            )

            // message delay
            numberInput(
                htmlId = "messageDelay",
                label = Localization["config_page_messageDelay_label"],
                placeholderText = Localization["config_page_messageDelay_placeholder"],
                default = 0.0,
                min = 0.0,
                max = 15.0,
                step = 1.0,
                value = messageDelay?.inWholeSeconds?.toDouble() ?: 0.0,
                onChange = {
                    messageDelay = if (it == 0.0) {
                        null
                    } else {
                        it?.seconds
                    }
                }
            )

            // shadow color
            colorChooser(
                htmlId = "shadowColor",
                placeholder = Localization["config_page_shadowColor_placeholder"],
                label = Localization["config_page_shadowColor_label"],
                value = shadowColorInput,
                onChange = { shadowColorInput = it }
            )

            // shadow blur radius
            numberInput(
                htmlId = "shadowBlurRadius",
                label = Localization["config_page_shadowBlurRadius_label"],
                placeholderText = Localization["config_page_shadowBlurRadius_placeholder"],
                default = 5.0,
                min = 0.0,
                max = 15.0,
                step = 0.5,
                value = shadowBlurRadiusInput,
                onChange = {
                    if (it != null) {
                        shadowBlurRadiusInput = it
                    }
                }
            )

            // background color
            colorChooser(
                htmlId = "backgroundColor",
                placeholder = Localization["config_page_backgroundColor_placeholder"],
                label = Localization["config_page_backgroundColor_label"],
                value = backgroundColorInput,
                onChange = { backgroundColorInput = it }
            )

            // background color
            numberInput(
                htmlId = "backgroundAlpha",
                label = Localization["config_page_backgroundAlpha_label"],
                placeholderText = Localization["config_page_backgroundAlpha_placeholder"],
                default = 0.9,
                min = 0.0,
                max = 1.0,
                step = 0.05,
                value = backgroundAlphaInput,
                onChange = { backgroundAlphaInput = it ?: 0.0 }
            )
        }

        mRow(additionalClass = "vertical-align") {

            mCol(small = 12) {
                h4 { +Localization["config_page_link_title"] }
            }

            mCol(small = 10, additionalClass = "indigo darken-4") {
                p {
                    css {
                        wordBreak = WordBreak.breakAll
                    }
                    +generateOverlayLink(
                        channelNamesInput = channelNamesInput,
                        fontColorInput = fontColorInput,
                        fontSizeInput = fontSizeInput,
                        hiddenUsernamesInput = hiddenUsernamesInput,
                        showChannelProfileImage = showChannelProfileImage,
                        hideCommands = hideCommands,
                        loadRecentMessages = loadRecentMessages,
                        disableSharedChatMessages = disableSharedChatMessages,
                        showPredictionBadges = showPredictionBadges,
                        showSubscriberBadges = showSubscriberBadges,
                        showModerationBadges = showModerationBadges,
                        messageDelay = messageDelay,
                        shadowColorInput = shadowColorInput,
                        shadowBlurRadiusInput = shadowBlurRadiusInput,
                        backgroundColorInput = backgroundColorInput,
                        backgroundAlphaInput = backgroundAlphaInput,
                    )
                }
            }

            mCol(small = 2) {
                a {
                    className = ClassName("waves-effect waves-light btn blue")
                    +Localization["button_label_copy_to_clipboard"]

                    onClick = {
                        props.manager.writeClipboard(
                            generateOverlayLink(
                                channelNamesInput = channelNamesInput,
                                fontColorInput = fontColorInput,
                                fontSizeInput = fontSizeInput,
                                hiddenUsernamesInput = hiddenUsernamesInput,
                                showChannelProfileImage = showChannelProfileImage,
                                hideCommands = hideCommands,
                                loadRecentMessages = loadRecentMessages,
                                disableSharedChatMessages = disableSharedChatMessages,
                                showPredictionBadges = showPredictionBadges,
                                showSubscriberBadges = showSubscriberBadges,
                                showModerationBadges = showModerationBadges,
                                messageDelay = messageDelay,
                                shadowColorInput = shadowColorInput,
                                shadowBlurRadiusInput = shadowBlurRadiusInput,
                                backgroundColorInput = backgroundColorInput,
                                backgroundAlphaInput = backgroundAlphaInput,
                            )
                        )
                        showSuccessToast(Localization["success_toast_copied_to_clipboard"])
                    }
                }
            }
        }

        mRow {
            mCol(small = 12) {
                h4 { +Localization["config_page_preview_title"] }
            }

            mCol(small = 12) {
                iframe {
                    css {
                        height = 500.px
                        width = 100.pct
                        backgroundColor = if (darkPreviewBackground) {
                            Color("#000000")
                        } else {
                            Color("#FFFFFF")
                        }
                        resize = Resize.horizontal
                    }
                    src = generateOverlayLink(
                        channelNamesInput = "PreviewUser",
                        fontColorInput = fontColorInput,
                        fontSizeInput = fontSizeInput,
                        hiddenUsernamesInput = hiddenUsernamesInput,
                        showChannelProfileImage = showChannelProfileImage,
                        hideCommands = hideCommands,
                        loadRecentMessages = loadRecentMessages,
                        disableSharedChatMessages = disableSharedChatMessages,
                        showPredictionBadges = showPredictionBadges,
                        showSubscriberBadges = showSubscriberBadges,
                        showModerationBadges = showModerationBadges,
                        messageDelay = messageDelay,
                        shadowColorInput = shadowColorInput,
                        shadowBlurRadiusInput = shadowBlurRadiusInput,
                        backgroundColorInput = backgroundColorInput,
                        backgroundAlphaInput = backgroundAlphaInput,
                        isPreview = true,
                    )
                }

            }
        }

        mRow {
            mCol(small = 12, additionalClass = "right-align") {
                a {
                    className = if (darkPreviewBackground) {
                        ClassName("waves-effect waves-light btn gray")
                    } else {
                        ClassName("waves-effect waves-light btn gray disabled")
                    }
                    +Localization["config_page_preview_light_preview_background_button_label"]

                    onClick = {
                        darkPreviewBackground = false
                    }
                }
                +" "
                a {
                    className = if (darkPreviewBackground) {
                        ClassName("waves-effect waves-light btn gray disabled")
                    } else {
                        ClassName("waves-effect waves-light btn gray")
                    }
                    +Localization["config_page_preview_dark_preview_background_button_label"]

                    onClick = {
                        darkPreviewBackground = true
                    }
                }
            }
        }


        mRow {
            mCol(small = 12) {
                p {
                    +Localization["config_page_possible_query_parameters_title"]
                    ul {
                        li {
                            b { +"channels" }
                            +": ${Localization["config_page_query_parameter_explanation_channels"]}"
                        }
                        li {
                            b { +"fontColor" }
                            +": ${Localization["config_page_query_parameter_explanation_fontColor"]}"
                        }
                        li {
                            b { +"showChannelProfileImage" }
                            +": ${Localization["config_page_query_parameter_explanation_showChannelProfileImage"]}"
                        }
                        li {
                            b { +"hiddenUsernames" }
                            +": ${Localization["config_page_query_parameter_explanation_hiddenUsernames"]}"
                        }
                        li {
                            b { +"hideCommands" }
                            +": ${Localization["config_page_query_parameter_explanation_hideCommands"]}"
                        }
                        li {
                            b { +"loadRecentMessages" }
                            +": ${Localization["config_page_query_parameter_explanation_loadRecentMessages"]}"
                        }
                        li {
                            b { +"showPredictionBadges" }
                            +": ${Localization["config_page_query_parameter_explanation_showPredictionBadges"]}"
                        }
                        li {
                            b { +"showSubscriberBadges" }
                            +": ${Localization["config_page_query_parameter_explanation_showSubscriberBadges"]}"
                        }
                        li {
                            b { +"showModerationBadges" }
                            +": ${Localization["config_page_query_parameter_explanation_showModerationBadges"]}"
                        }
                        li {
                            b { +"messageDelay" }
                            +": ${Localization["config_page_query_parameter_explanation_messageDelay"]}"
                        }
                        li {
                            b { +"shadowColor" }
                            +": ${Localization["config_page_query_parameter_explanation_shadowColor"]}"
                        }
                        li {
                            b { +"shadowBlurRadius" }
                            +": ${Localization["config_page_query_parameter_explanation_shadowBlurRadius"]}"
                        }
                        li {
                            b { +"backgroundColor" }
                            +": ${Localization["config_page_query_parameter_explanation_backgroundColor"]}"
                        }
                        li {
                            b { +"backgroundAlpha" }
                            +": ${Localization["config_page_query_parameter_explanation_backgroundAlpha"]}"
                        }
                    }
                }
            }
        }
    }

    mFooter()
}


private fun ChildrenBuilder.colorChooser(
    htmlId: String,
    placeholder: String,
    label: String,
    value: String,
    onChange: (String) -> Unit,
) {
    div {
        className = ClassName("input-field col s11 m5 l5")
        input {
            this.className = ClassName("white-text")
            this.id = htmlId
            this.placeholder = placeholder
            this.type = InputType.text
            this.value = value
            this.onChange = {
                onChange.invoke(it.target.value)
            }
        }
        label {
            className = ClassName("active")
            htmlFor = htmlId
            +label
        }
    }

    div {
        className = ClassName("input-field col s1 m1 l1")

        input {
            this.className = ClassName("white-text")
            this.type = InputType.color
            this.value = value
            this.onChange = {
                onChange.invoke(it.target.value)
            }
        }
    }
}

private fun ChildrenBuilder.checkbox(
    falseLabel: String,
    trueLabel: String,
    value: Boolean,
    onChange: (Boolean) -> Unit,
) {
    // show channel profile image
    div {
        className = ClassName("col s12 m6 l6")
        br()
        div {
            className = ClassName("switch")
            label {
                +falseLabel
                input {
                    type = InputType.checkbox
                    checked = value
                    this.onChange = {
                        onChange.invoke(it.target.checked)
                    }
                }
                span {
                    className = ClassName("lever")
                }
                +trueLabel
            }
        }
        br()
    }
}

private fun ChildrenBuilder.numberInput(
    htmlId: String,
    label: String,
    placeholderText: String,
    default: Double?,
    min: Double?,
    max: Double?,
    step: Double,
    value: Double?,
    onChange: (Double?) -> Unit,
) {
    div {
        className = ClassName("input-field col s12 m6 l6")
        input {
            className = ClassName("white-text")
            id = htmlId
            placeholder = placeholderText
            type = InputType.number
            this.value = value
            defaultValue = default
            this.min = min
            this.max = max
            this.step = step
            this.onChange = {
                it.target.value.toDoubleOrNull()?.let { number ->
                    onChange.invoke(number)
                }
            }
        }
        label {
            className = ClassName("active")
            htmlFor = htmlId
            +label
        }
    }
}